<template>
  <van-tabbar route active-color="#3388b9">
    <van-tabbar-item icon="wap-home" to="/wgs/">首页</van-tabbar-item>
    <van-tabbar-item icon="column" to="/wgs/list">设备</van-tabbar-item>
    <van-tabbar-item icon="volume" to="/wgs/warning">告警</van-tabbar-item>
    <van-tabbar-item icon="setting" to="/wgs/about">关于</van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  name: 'tabber',
}
</script>

<template>
  <div class="about">
    <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item,index) in swipeImg" :key="index"><img :src="item" width="100%" height="100%"></van-swipe-item>
    </van-swipe>
    <div class="info">
      <van-row>
        <van-col span="8" class="border-right" @click="listStatus('online')"><span v-text="count.online"></span><p>正常</p></van-col>
        <van-col span="8" class="border-right" @click="listStatus('alarm')"><span v-text="count.alarm"></span><p>告警</p></van-col>
        <van-col span="8" @click="listStatus('offline')"><span v-text="count.offline"></span><p>离线</p></van-col>
      </van-row>
    </div>
    <div class="dev-list">
      <div v-if="!devEmpty">
          <div v-if="devList.length != 0">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
                <div class="block" v-for="(item,index) in devList" :key="index" @click="goDetail(item.devCode)">
                  <van-row :class="{
                      'flex-end':true,
                      'online':item.devStatus == 'online',
                      'offline':item.devStatus == 'offline',
                      'alarm':item.devStatus == 'alarm'
                    }">
                      <van-col span="6">
                          <img :src="
                            item.devStatus === 'online' ? require('@/assets/wgs/img/thumb-green.png') :  
                            item.devStatus === 'alarm' ? require('@/assets/wgs/img/thumb-alarm.png') : require('@/assets/wgs/img/thumb-gray.png')
                          " width="60px" height="40px">
                      </van-col>
                      <van-col span="18">
                          <div class="title" v-text="item.devName"></div>
                          <!-- <div>{{item.devRoad}} - {{item.devStake || '-'}}</div> -->
                          <div v-text="item.location || '设备暂未设置地址'"></div>
                      </van-col>
                  </van-row>
                </div>
            </van-list>
          </div>
          <div v-else><van-loading class="z-loading" size="24px" style="margin:20vh 0" vertical>数据努力加载中...</van-loading></div>
      </div>
      <div v-else><van-empty description="设备列表为空" /></div>
      </div>
    <tabbar />
  </div>
</template>

<script>
import tabbar from '@/components/wgs/tabBar.vue'

export default {
  name: 'devIndex',
  components: {
    tabbar
  },
  data(){
    return{
      swipeImg:[
        require('@/assets/wgs/img/banner/banner1.jpg'),
        require('@/assets/wgs/img/banner/banner2.jpg'),
        require('@/assets/wgs/img/banner/banner3.jpg')
      ],
      count:{
        online:0,
        alarm:0,
        offline:0,
      },
      projectCode:'',
      loading:false,
      finished:false,
      pageSize:20,
      pageNum:1,
      devList:[],
      devEmpty:false,
      isLoading: false,
      projectList:[]
    }
  },
  methods:{
      // 点击设备进入详情页面
      goDetail(devCode){
        this.$router.push({path:'/wgs/detail',query: {devCode:devCode}})
      },
      listStatus(status){
        //this.$router.push({path:'/wgs/devList',query: {status:status}})
        
        this.pageNum = 1;
        this.devList = [];
        this.onLoad(status);
      },
      // 获取分页数据信息
      onLoad(status){
        this.$api.WGS.getDevicePageList(this.pageNum,this.pageSize,{projectCode:this.projectCode,devStatus:status}).then((data)=>{
            if(this.pageNum === 1 && data.length === 0){
              this.devEmpty = true;
              return
            }else{
              this.devEmpty = false;
            }
            this.loading = false;
            this.devList = this.devList.concat(data);
            if(data.length < this.pageSize) {
              this.finished = true;
              return
            }else{
              this.finished = false;
            }
            this.pageNum ++;
        })
      },
      // 获取设备数
      getCount(){
        this.$api.WGS.statisticsInfo({projectCode:this.projectCode}).then( d => {
          this.count = { ...d };
        })
      }
  },
  mounted(){
      this.projectCode = window.sessionStorage.getItem('projectCode');
      this.visitor = localStorage.getItem('visitor');
      this.onLoad();
      this.getCount();
  }
}
</script>
<style lang="scss" scoped>
.info{
    padding: 4%;
    background: #2c6a8e;
    color: white;
    margin-top: -2%;
    .border-right{border-right:1px solid #989898ad}
    span{font-size: 1.5rem;font-weight: bold;}
    p{margin: 0;font-size: .9rem;}
}
.dev-list{
    margin-bottom: 22%;
    .block{
        border: 1px solid #f5f5f5;
        background-color: white;
        padding: 4%;
        margin-bottom: 10px;
        font-size: .8rem;
        text-align: left;
        box-shadow: 0 2px 4px #e4e4e4;
        .online{color:#35a98e;}
        .offline{color:#5d5e5f;}
        .alarm{color:#de0101;}
        img{display: block;margin: 0 auto;}
        .title{
            font-weight: bold;
            margin-bottom: 4px;
        }
    }
    .flex-end{
      display: flex;
      align-items: flex-end;
    }
}
</style>